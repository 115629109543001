<cca-wizard-step
  (formSubmit)="onSubmit()"
  [formGroup]="form"
  [caption]="'TP5 (2021-2022)'"
  heading="Are you providing baseline and target information for TP5 (2021 to 2022)?"
  data-testid="baseline-exists-form"
>
  <div formControlName="exist" govuk-radio>
    <govuk-radio-option [value]="true" [label]="'Yes'"></govuk-radio-option>
    <govuk-radio-option [value]="false" [label]="'No'"></govuk-radio-option>
  </div>
</cca-wizard-step>

<hr class="govuk-footer__section-break govuk-!-margin-bottom-3" />
<netz-return-to-task-or-action-page></netz-return-to-task-or-action-page>
