<cca-wizard-step
  (formSubmit)="onSubmit()"
  [formGroup]="form"
  [caption]="facility.facilityDetails.name"
  heading="{{ facility.status !== 'NEW' ? 'Change' : 'Add' }} facility details"
  data-testid="add-facility-details-form"
>
  <div formControlName="facilityId" cca-text-input label="Facility code"></div>
  <div formControlName="name" class="govuk-!-width-two-thirds" cca-text-input label="Site name"></div>

  <div formControlName="isCoveredByUkets" govuk-radio legend="Is this facility covered by UK ETS?">
    <govuk-radio-option [value]="true" [label]="'Yes'">
      <ng-container govukConditionalContent>
        <div
          widthClass="govuk-!-width-one-third"
          formControlName="uketsId"
          cca-text-input
          label="UK ETS Installation Identifier"
        ></div>
      </ng-container>
    </govuk-radio-option>
    <govuk-radio-option [value]="false" [label]="'No'"></govuk-radio-option>
  </div>

  <div formControlName="applicationReason" govuk-radio legend="Application reason">
    <govuk-radio-option
      [value]="'NEW_AGREEMENT'"
      [label]="'NEW_AGREEMENT' | applicationReasonType"
    ></govuk-radio-option>
    <govuk-radio-option [value]="'CHANGE_OF_OWNERSHIP'" [label]="'CHANGE_OF_OWNERSHIP' | applicationReasonType">
      <ng-container govukConditionalContent>
        <div
          widthClass="govuk-!-width-one-third"
          formControlName="previousFacilityId"
          cca-text-input
          label="Previous facility ID"
          hint="The previous facility ID is mandatory if the facility is transferred from a previous target unit with a CCA agreement"
        ></div>
      </ng-container>
    </govuk-radio-option>
  </div>

  <h2 class="govuk-heading-m">Facility address</h2>
  <div formControlName="sameAddress" govuk-checkboxes>
    <govuk-checkbox [value]="true" label="The facility address is the same as target unit address"></govuk-checkbox>
  </div>

  <div formGroupName="facilityAddress">
    <cca-account-address-input></cca-account-address-input>
  </div>
</cca-wizard-step>

<hr class="govuk-footer__section-break govuk-!-margin-bottom-3" />
<netz-return-to-task-or-action-page></netz-return-to-task-or-action-page>
