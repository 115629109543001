<cca-wizard-step
  (formSubmit)="onSubmit()"
  [formGroup]="form"
  caption="Change"
  heading="Target unit details"
  data-testid="change-target-unit-details-form"
>
  <div class="govuk-!-width-two-thirds">
    <div
      formControlName="operatorName"
      cca-text-input
      label="Operator name"
      hint="For limited companies, limited partnerships or limited liability partnerships this should
      be the name which currently appears on Companies House"
    ></div>

    <div
      formControlName="operatorType"
      cca-text-input
      [valueTransform]="transformOperatorType"
      label="Operator type"
    ></div>

    <div formControlName="companyRegistrationNumber" cca-text-input label="Company registration number"></div>
    <div formControlName="subsectorAssociationName" cca-text-input label="Subsector"></div>
  </div>
</cca-wizard-step>

<hr class="govuk-footer__section-break govuk-!-margin-bottom-3" />
<netz-return-to-task-or-action-page></netz-return-to-task-or-action-page>
