<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->

<div [id]="'more-less-link-' + index" [class]="class()">
  {{ text }}
</div>

@if (isTextOverflow('more-less-link-' + index)) {
  <a class="govuk-link" (click)="moreLessClicked()">
    {{ this.moreIsClicked() ? 'less' : 'more' }}
  </a>
}
