<cca-wizard-step
  (formSubmit)="onSubmit()"
  [formGroup]="form"
  caption="Change"
  heading="Operator address"
  data-testid="change-operator-address-form"
>
  <cca-account-address-input data-testid="account-address-input"></cca-account-address-input>
</cca-wizard-step>

<hr class="govuk-footer__section-break govuk-!-margin-bottom-3" />
<netz-return-to-task-or-action-page></netz-return-to-task-or-action-page>
