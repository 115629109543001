@if (facility(); as facility) {
  <cca-wizard-step
    (formSubmit)="onSubmit()"
    [formGroup]="form"
    [caption]="facility.name"
    heading="Add facility contact details"
    data-testid="add-facility-contact-form"
  >
    <div formControlName="sameContact" govuk-checkboxes>
      <govuk-checkbox
        [value]="true"
        label="The facility contact details are the same as the target unit administrative contact details"
      ></govuk-checkbox>
    </div>

    <h2 class="govuk-heading-m">Name</h2>
    <div formControlName="firstName" govuk-text-input label="First name" widthClass="govuk-!-width-one-half"></div>
    <div formControlName="lastName" govuk-text-input label="Last name" widthClass="govuk-!-width-one-half"></div>
    <div
      formControlName="email"
      govuk-text-input
      inputType="email"
      label="Email address"
      widthClass="govuk-!-width-one-half"
    ></div>

    <h2 class="govuk-heading-m">Address</h2>
    <div formControlName="sameAddress" govuk-checkboxes>
      <govuk-checkbox [value]="true" label="Use the facility address"></govuk-checkbox>
    </div>

    <div formGroupName="address">
      <cca-account-address-input></cca-account-address-input>
    </div>

    <h2 class="govuk-heading-m">Phone number</h2>
    <div cca-phone-input formControlName="phoneNumber" label="Phone number 1"></div>
  </cca-wizard-step>
}

<hr class="govuk-footer__section-break govuk-!-margin-bottom-3" />
<netz-return-to-task-or-action-page></netz-return-to-task-or-action-page>
