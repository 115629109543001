<div
  autoComplete="given-name"
  formControlName="firstName"
  govuk-text-input
  label="First name"
  class="govuk-!-margin-bottom-3"
  widthClass="govuk-!-width-three-quarters"
></div>

<div
  autoComplete="family-name"
  formControlName="lastName"
  govuk-text-input
  label="Last name"
  widthClass="govuk-!-width-three-quarters"
></div>

<div
  autoComplete="job-title"
  formControlName="jobTitle"
  govuk-text-input
  label="Job Title"
  class="govuk-!-padding-top-3 govuk-!-padding-bottom-3"
  widthClass="govuk-!-width-three-quarters"
></div>

<h3 class="govuk-heading-s">{{ isNotification ? 'Address' : 'Your address' }}</h3>

<cca-county-address-input formGroupName="address"></cca-county-address-input>

<h3 class="govuk-heading-s">{{ isNotification ? 'Phone number' : 'Your phone number' }}</h3>

@switch (phoneType) {
  @case ('full') {
    <div cca-phone-input formControlName="phoneNumber" label="Phone number 1" class="govuk-!-margin-bottom-1"></div>
    <div cca-phone-input formControlName="mobileNumber" label="Phone number 2 (optional)"></div>
  }

  @case ('national') {
    <div
      autoComplete="tel-national"
      formControlName="phoneNumber"
      govuk-text-input
      inputType="tel"
      label="Phone number 1"
      widthClass="govuk-!-width-one-third"
    ></div>

    <div
      autoComplete="mobile tel-national"
      formControlName="mobileNumber"
      govuk-text-input
      inputType="tel"
      label="Phone number 2 (optional)"
      widthClass="govuk-!-width-one-third"
    ></div>
  }
}

@if (!isNotification) {
  <div
    autoComplete="email"
    formControlName="email"
    govuk-text-input
    hint="All system alerts, notices and official communications will always be sent by email"
    label="Email address"
    widthClass="govuk-!-width-three-quarters"
  ></div>
} @else {
  <div
    autoComplete="email"
    formControlName="email"
    govuk-text-input
    label="Email address"
    widthClass="govuk-!-width-three-quarters"
  ></div>
}
