<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  @if (isSummaryDisplayedSubject | async) {
    <cca-csv-error-summary [form]="formGroup"></cca-csv-error-summary>
  }

  <netz-page-heading [caption]="caption">{{ heading }}</netz-page-heading>

  <ng-content></ng-content>

  @if (!hideSubmit) {
    <button netzPendingButton govukButton type="submit">{{ submitText }}</button>
  }

  @if (showReturnLink) {
    <div>
      <netz-return-to-task-or-action-page />
    </div>
  }

  @if (showCancelLink) {
    <div>
      <a govukLink [routerLink]="cancelLinkPath">Cancel</a>
    </div>
  }
</form>
