export enum TaskItemStatus {
  NOT_STARTED = 'NOT_STARTED',
  CANNOT_START_YET = 'CANNOT_START_YET',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  APPROVED = 'APPROVED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  UNDECIDED = 'UNDECIDED',
}
